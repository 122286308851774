import React, { useState, useContext, useEffect } from 'react';
// import Navbar from '../components/Navbar';
import { DataContext } from '../context';
import Footer from '../components/Footer';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import './PagesCss.css'
import { Col, Container, Form, Button, Row, Navbar } from 'react-bootstrap';
import { FiHome } from 'react-icons/fi';
import logo from "../images/banner_dark.svg";

const Openings = () => {
  const context = useContext(DataContext);
  const { footer } = context.allData;
  const { handleSearch, handleOffcanvas } = context;
  const history = useHistory();
  const location = useLocation();

  // Extract data from location state
  const [selectedOpening, setSelectedOpening] = useState(location.state?.selectedOpening || {});

  const [title, setTitle] = useState(selectedOpening.title || "");
  const [domain, setDomain] = useState(selectedOpening.domain || "");
  const [jobLocation, setJobLocation] = useState(selectedOpening.location || "");
  const [summary, setSummary] = useState(selectedOpening.summery || "");
  const [experience, setExperience] = useState(selectedOpening.experience || "");
  const [noOfOpenings, setNoOfOpenings] = useState(selectedOpening.number_of_openings || "");
  const [lastDate, setLastDate] = useState(selectedOpening.last_date || "");
  const [skills, setSkills] = useState(selectedOpening.skills || "");
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(!!selectedOpening.id); 

  const [responsibilities,setResponsibilities] = useState(selectedOpening.responsibilities || "");
  const [benefits, setBenefits] = useState(selectedOpening.benefits || "");
  const [salary, setSalary] = useState(selectedOpening.salary || "");
  const [qualifications, setQualifications] = useState(selectedOpening.qualifications || "");
  const [reqId, setReqId] = useState(selectedOpening.reqId || "");



  useEffect(() => {
    const LoggedIn = sessionStorage.getItem('isLoggedIn');
    if (!LoggedIn) {
      history.push('/login');
    }
  }, [history]);

  const allOpenings = () => {
    history.push('/adminJobs');
  };

  const jobOpenings = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const data = {
        title,
        skills,
        location: jobLocation,
        experience,
        summery: summary,
        domain,
        number_of_openings: noOfOpenings,
        last_date: lastDate,
      };

      const url = isEdit
        ? `https://trivont.com/api/openings/update/${selectedOpening.id}/`  // PUT URL
        : 'https://trivont.com/api/openings/insert/view/'; // POST URL

      const method = isEdit ? 'put' : 'post';


      axios({ method, url, data })
        .then(response => {
          if (response.data.status === 'success') {
            alert("Data Successfully Saved");

            setTitle("")
            setDomain("")
            setJobLocation("")
            setSkills("")
            setSummary("")
            setExperience("")
            setNoOfOpenings("")
            setLastDate("")

            history.push('/adminJobs');

          } else {
            alert("An error occurred, please try again later.")
          }

        })
        .catch(error => { 
          console.log(error); 
          alert("An error occurred, please try again later.")
        });
    } else {
      setErrors(errors);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!title.trim()) errors.title = "Title is required.";
    if (!domain.trim()) errors.domain = "Domain is required.";
    if (!jobLocation.trim()) errors.jobLocation = "Job location is required.";
    if (!summary.trim()) errors.summary = "Summary is required.";
    if (!experience.trim()) errors.experience = "Experience is required.";
    if (!noOfOpenings) errors.noOfOpenings = "Number of openings is required.";
    if (!lastDate.trim()) errors.lastDate = "Last date is required.";
    if (!skills.trim()) errors.skills = "Skills are required.";
    return errors;
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  };

  return (
    <>
<Navbar className="bg-body-tertiary mt-3">
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={logo}
              width="200"
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <a href="/">
                <FiHome style={{ fontSize: '24px' }} /> Back to Home
              </a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br/>
      <div className="d-flex justify-content-end" style={{marginRight:'30px'}}>
        <button onClick={allOpenings} style={{ border:'1px solid black', borderRadius:'5px', padding:'5px'}}>View All Openings</button>
      </div>
      <div>
      <form id="User_userForm" className="container p-4 rounded shadow-sm bg-white" style={{ maxWidth: '800px', margin: 'auto' }}>
        <h2 className="text-center mb-4">Job Opening Details</h2>

        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label fw-bold">Title <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Title"
              autoComplete="off"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {errors.title && <small className="text-danger">{errors.title}</small>}
          </div>
          <div className="col-md-6">
            <label className="form-label fw-bold">Domain <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Domain"
              autoComplete="off"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
            {errors.domain && <small className="text-danger">{errors.domain}</small>}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label fw-bold">Salary <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Salary"
              autoComplete="off"
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label fw-bold">Location <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Location"
              autoComplete="off"
              value={jobLocation}
              onChange={(e) => setJobLocation(e.target.value)}
            />
            {errors.jobLocation && <small className="text-danger">{errors.jobLocation}</small>}
          </div>
          <div className="col-md-4">
            <label className="form-label fw-bold">Experience <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Experience"
              autoComplete="off"
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
            />
            {errors.experience && <small className="text-danger">{errors.experience}</small>}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label fw-bold">Apply Before <span className="text-danger">*</span></label>
            <input
              type="date"
              className="form-control"
              autoComplete="off"
              value={lastDate}
              min={getTomorrowDate()}
              onChange={(e) => setLastDate(e.target.value)}
            />
            {errors.lastDate && <small className="text-danger">{errors.lastDate}</small>}
          </div>
          <div className="col-md-4">
            <label className="form-label fw-bold">Req ID <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Req ID"
              autoComplete="off"
              value={reqId}
              onChange={(e) => setReqId(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label fw-bold">Number of Openings <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Number of Openings"
              autoComplete="off"
              value={noOfOpenings}
              onChange={(e) => setNoOfOpenings(e.target.value)}
            />
            {errors.noOfOpenings && <small className="text-danger">{errors.noOfOpenings}</small>}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label fw-bold">Skills <span className="text-danger">*</span></label>
            <textarea
              className="form-control"
              placeholder="Enter required skills"
              rows="4"
              value={skills}
              onChange={(e) => setSkills(e.target.value)}
            ></textarea>
            {errors.skills && <small className="text-danger">{errors.skills}</small>}
          </div>
          <div className="col-md-6">
            <label className="form-label fw-bold">Qualifications <span className="text-danger">*</span></label>
            <textarea
              className="form-control"
              placeholder="Enter qualifications"
              rows="4"
              value={qualifications}
              onChange={(e) => setQualifications(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label fw-bold">Responsibilities <span className="text-danger">*</span></label>
            <textarea
              className="form-control"
              placeholder="Enter job responsibilities"
              rows="4"
              value={responsibilities}
              onChange={(e) => setResponsibilities(e.target.value)}
            ></textarea>
          </div>
          <div className="col-md-6">
            <label className="form-label fw-bold">Benefits <span className="text-danger">*</span></label>
            <textarea
              className="form-control"
              placeholder="Enter benefits"
              rows="4"
              value={benefits}
              onChange={(e) => setBenefits(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12">
            <label className="form-label fw-bold">Summary <span className="text-danger">*</span></label>
            <textarea
              className="form-control"
              placeholder="Enter job summary"
              rows="4"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            ></textarea>
            {errors.summary && <small className="text-danger">{errors.summary}</small>}
          </div>
        </div>

        <div className="text-center">
          <button type="button" className="btn btn-primary px-4 py-2" onClick={jobOpenings}>Submit</button>
        </div>
      </form>

      </div>
      <Footer footer={footer} />
    </>
  );
};

export default Openings;
