import React from 'react';
import { Card, Col,ROW } from 'react-bootstrap';
import { BsArrowRight } from 'react-icons/bs';
import './customcomponent.css'
const CarouselCard = ({ title, subtitle, buttonText, gradientColor, imageUrl,textColor,Iwidth,buttonLink,containerWidth,marginleftvalue,mbt }) => {
 
  const cardStyle = imageUrl ? { backgroundImage: `url(${imageUrl})` } : { backgroundImage: gradientColor };

  return (
    <div>
      
        <Card className='carouselCard align-items-end d-flex
         justify-content-center' style={{backgroundImage: gradientColor, color: textColor ? textColor : '#242424' }}>
          <Card.Body className="d-flex flex-column align-items-start justify-content-end">
            <Card.Title className="display-4 carouselTitle" style={{ textAlign: 'left' }}>{title}</Card.Title>
            <Card.Subtitle className="mb-2 h5 " style={{ textAlign: 'left' }}>{subtitle}</Card.Subtitle>
            <a href={buttonLink} style={{
              color:'#ffff',cursor:'pointer'
            }}>
  {buttonText ? buttonText : 'Learn More'} <BsArrowRight />
</a>
            
          </Card.Body>
          <div className='Card__imgContainer ' >
      {imageUrl && <img src={imageUrl} alt={title}   className='CarouselImageWidth'  />}
    </div>
        </Card>
   
     
       
     
  
    </div>

  );
};

export default CarouselCard;
