import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function Popup({ show, setShowPopUpUser ,title}) {
  const handleClose = () => setShowPopUpUser(false);

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
      {/* Modal Body */}
      <Modal.Body className="text-center">
        <h3 className="my-3">{title}</h3>
      </Modal.Body>

      {/* Modal Footer */}
      <Modal.Footer className="justify-content-center">
        <Button
          className="btn-lg"
          variant="primary"
          onClick={handleClose}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Popup;
