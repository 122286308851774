import React, { useState, useContext, useEffect } from 'react';
import Navbar from '../components/Navbar';
import { DataContext } from '../context';
import Footer from '../components/Footer';
import BannerSectionCareer from '../components/customComponents/bannerSectionCareer';
import CyberS from '../images/BANNERS/careerImage.jpg';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import './PagesCss.css'
import Popup from '../components/Popup';
import { FaUser, FaBriefcase, FaFileAlt, FaGraduationCap} from 'react-icons/fa';



const CareerReg = () => {
  const context = useContext(DataContext);
  const { footer } = context.allData;
  const [OpeningsData, setOpeningsData] = useState([]);
  const { handleSearch, handleOffcanvas } = context;

  const [user_Fname, setuser_Fname] = useState("");
  const [user_Lname, setuser_Lname] = useState("");
  const [user_email, setuser_email] = useState("");
  const [user_mobile, setuser_mobile] = useState("");
  const [file_resume, setfile_resume] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1);

  const [Popupuser, setPopupuser] = useState(false)
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([])
  const [userCountry, setUserCountry] = useState('');
  const [userState, setUserState] = useState('');
  const [user_address, setUserAddress] = useState('');
  const [user_city, setUserCity] = useState('');
  const [user_zipcode, setUserZipCode] = useState('')
  const [user_linkden, setUserLinkden] = useState('')
  const [user_website, setUserWebsite] = useState('')

  const [user_degree, setuser_Degree] = useState('')
  const [user_college, setuser_College] = useState('')
  const [user_feildstudy, setuser_FeildStudy] = useState('')
  const [user_year, setuser_Year] = useState('')
  
  const [user_previousjob, setUserPreviousJob] = useState('')
  const [user_companyname, setUserCompanyName] = useState('')
  const [user_exprience, setUserExprience] = useState('')

  const [user_responsibilities, setUserResponsibilities] = useState('')



  useEffect(() => {
    axios
      .get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const countryList = response.data.map((country) => country.name.common);
        setCountries(countryList.sort()); // Alphabetically sorted
      })
      .catch((error) => console.error('Error fetching countries:', error));
  }, []);


  useEffect(() => {
    if (userCountry) {
      axios
        .post('https://countriesnow.space/api/v0.1/countries/states', {
          country: userCountry,
        })
        .then((response) => {
          if (response.data.data && response.data.data.states) {
            setStates(response.data.data.states.map((state) => state.name));
          } else {
            setStates([]); // No states found
          }
        })
        .catch((error) => {
          console.error('Error fetching states:', error);
          setStates([]); // Clear states on error
        });
    } else {
      setStates([]); // Clear states if no country is selected
    }
  }, [userCountry]);
  

  const steps = [
    { label: 'Personal Information', icon: <FaUser/> },
    { label: 'Professional Information', icon: <FaBriefcase/> },
    { label: 'Education & Work Experience', icon: <FaGraduationCap /> },
    { label: 'Documents', icon: <FaFileAlt/> }
  ];


  const location = useLocation();
  const { jobId } = location.state || {};

  const validateForm = () => {
    const errors = {};
    if (!user_Fname.trim()) errors.user_Fname = "First name is required.";
    if (!user_Lname.trim()) errors.user_Lname = "Last name is required.";
    if (!user_email.trim()) errors.user_email = "Email is required.";
    if (!user_mobile.trim()) errors.user_mobile = "Mobile number is required.";
    if (!file_resume) errors.file_resume = "Resume is required.";
    return errors;
  };


  const applyForJob = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const data = new FormData();
      data.append('first_name', user_Fname);
      data.append('last_name', user_Lname);
      data.append('email', user_email);
      data.append('mobile', user_mobile);

      data.append('address', user_address);
      data.append('country', userCountry);
      data.append('state', userState);
      data.append('city', user_city);
      data.append('zipcode', user_zipcode);

      data.append('file_path', file_resume);
      data.append('opening', parseInt(jobId, 10));


      try {
        setIsSubmitting(true); // Set submitting state
        const response = await axios.post('https://trivont.com/api/career/insert/view/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

          if (response.data.status === 'success') {
            setPopupuser(true)

            const job = OpeningsData.find(opening => opening.id === jobId);
            history.push('/responsePage', { jobTitle: job.title, userName: `${user_Fname} ${user_Lname}` });

          } else {
            alert("Oops! Something went wrong. Please try again later.")
          }
        } catch (error) {
        console.log(error);
        alert("Oops! Something went wrong. Please try again later.");
      } finally {
        setIsSubmitting(false); // Reset submitting state
      }
    } else {
      setErrors(errors);
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setuser_email(email);
    if (!/\S+@\S+\.\S+/.test(email)) {
      setErrors((prev) => ({ ...prev, user_email: "Email address is invalid." }));
    } else {
      setErrors((prev) => ({ ...prev, user_email: null }));
    }
  };

  const handleMobileChange = (e) => {
    const mobile = e.target.value;
    setuser_mobile(mobile);
    if (!/^\d{10}$/.test(mobile)) {
      setErrors((prev) => ({ ...prev, user_mobile: "Mobile number is invalid. It should be 10 digits." }));
    } else {
      setErrors((prev) => ({ ...prev, user_mobile: null }));
    }
  };


  const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    if (file.size > 5 * 1024 * 1024) { // Check if file size exceeds 5MB
      alert("File size should not exceed 5MB.");
      e.target.value = ''; // Reset the input value to clear the selected file
    } else if (file.type !== 'application/pdf') {
      alert("Please select a PDF file.");
      e.target.value = ''; // Reset the input value to clear the selected file
    } else {
      setfile_resume(file);
    }
  }
};



  useEffect(() => {
    fetchData();
    // console.log(role)
  }, []);

  const fetchData = () => {
    axios.get('https://trivont.com/api/openings/view/')
      .then(response => setOpeningsData(response.data))
      .catch(error =>  setErrorMessage("Something went wrong. Please try again later."));
  };

  const formStyle = {
    backgroundColor: '#f8f9fa',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    width:'1000px'
  };
  
  const rowStyle = {
    display:'flex',
    marginBottom: '20px',
  };
  
  const requiredStyle = {
    color: 'red',
  };
  
  const inputStyle = {
    border: '1px solid #ced4da',
    borderRadius: '4px',
    padding: '10px',
    transition: 'border-color 0.3s',
  };
  
  const errorStyle = {
    color: 'red',
    fontSize: '0.875em',
    marginTop: '5px',
  };
  
  // const buttonStyle = {
  //   // marginTop: '20px',
  //   backgroundColor: '#007bff',
  //   borderRadius: '5px',
  //   color: 'white',
  //   padding: '10px 20px',
  //   border: 'none',
  //   cursor: 'pointer',
  //   transition: 'background-color 0.3s',
  // };
  
  const buttonHoverStyle = {
    backgroundColor: '#0056b3',
  };
  
  const buttonDisabledStyle = {
    backgroundColor: '#c0c0c0',
    cursor: 'not-allowed',
  };

  const nextStep = () => setStep((prev) => Math.min(prev + 1, steps.length));
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));
  



  return (
    <>
      <Navbar handler={{ handleSearch, handleOffcanvas }} headerTop="True" />
     {/*  <BannerSectionCareer title="Apply for Job" backgroundColor="#007bff" backgroundImage={CyberS} color="#fff" height="600px" customBackgroundSize='auto' />
*/}
      <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
        {errorMessage && <p>{errorMessage}</p>}

        <div className="progress-bar123">
        {steps.map((s, index) => (
          <div
            key={index}
            className={`step ${index < step ? 'completed' : ''}`}
            style={{width:'230px'}}
          >
            <div className="step-icon">{s.icon}</div>
            <span className="step-label">{s.label}</span>
            {index < steps.length - 1 && <div className="step-line" />}
          </div>
        ))}
      </div>

        {OpeningsData.filter(partner => partner.id === jobId).map((partner, index) => (
         <form className="CareerRegcontainerUser" style={formStyle}>

         {step === 1 && (
           <>
             <div className="row12345" style={rowStyle}>
               <div className="col-md-6">
                 <label className="form-label12345">First Name <span style={requiredStyle}>*</span></label>
                 <input
                   type="text"
                   className="form-control"
                   placeholder='Enter First Name'
                   autoComplete='off'
                   value={user_Fname}
                   required
                   style={inputStyle}
                   onChange={(e) => setuser_Fname(e.target.value)}
                 />
                 {errors.user_Fname && <p style={errorStyle}>{errors.user_Fname}</p>}
               </div>
 
               <div className="col-md-6">
                 <label className="form-label12345">Last Name <span style={requiredStyle}>*</span></label>
                 <input
                   type="text"
                   className="form-control"
                   placeholder='Enter Last Name'
                   autoComplete='off'
                   value={user_Lname}
                   required
                   style={inputStyle}
                   onChange={(e) => setuser_Lname(e.target.value)}
                 />
                 {errors.user_Lname && <p style={errorStyle}>{errors.user_Lname}</p>}
               </div>
             </div>

             <div className="row12345" style={rowStyle}>
               <div className="col-md-6">
                 <label className="form-label12345">Email <span style={requiredStyle}>*</span></label>
                 <input
                   type="email"
                   className="form-control"
                   placeholder='Enter Email'
                   autoComplete='off'
                   value={user_email}
                   required
                   style={inputStyle}
                   onChange={handleEmailChange}
                 />
                 {errors.user_email && <p style={errorStyle}>{errors.user_email}</p>}
               </div>
 
               <div className="col-md-6">
                 <label className="form-label12345">Mobile <span style={requiredStyle}>*</span></label>
                 <input
                   type="tel"
                   className="form-control"
                   placeholder='Enter Mobile Number'
                   autoComplete='off'
                   value={user_mobile}
                   required
                   style={inputStyle}
                   onChange={handleMobileChange}
                 />
                 {errors.user_mobile && <p style={errorStyle}>{errors.user_mobile}</p>}
               </div>
             </div>

             <div className="row12345" style={rowStyle}>
               <div className="col-md-12">
                 <label className="form-label12345">Address <span style={requiredStyle}>*</span></label>
                 <input
                   className="form-control"
                   placeholder='Enter Address'
                   autoComplete='off'
                   value={user_address}
                   style={inputStyle}
                   onChange={(e) => setUserAddress(e.target.value)}
                 />
               </div>
             </div>

             <div className="row12345" style={rowStyle}>
               
               <div className="col-md-3">
                 <label className="form-label12345">Country <span style={requiredStyle}>*</span></label>

                 <select
                  className="form-control"
                  value={userCountry}
                  onChange={(e) => setUserCountry(e.target.value)}
                >
                  <option value="" disabled>Select Country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>

               </div>
               <div className="col-md-3">
                 <label className="form-label12345">State <span style={requiredStyle}>*</span></label>
                 <select
                    className="form-control"
                    value={userState}
                    onChange={(e) => setUserState(e.target.value)}
                  >
                    <option value="" disabled>Select State</option>
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>

               </div>
               <div className="col-md-3">
                 <label className="form-label12345">City <span style={requiredStyle}>*</span></label>
                 <input
                   className="form-control"
                   placeholder='Enter City'
                   autoComplete='off'
                   value={user_city}
                   style={inputStyle}
                   onChange={(e) => setUserCity(e.target.value)}
                 />
               </div>

               <div className="col-md-3">
                 <label className="form-label12345">Zip Code <span style={requiredStyle}>*</span></label>
                 <input
                   className="form-control"
                   placeholder='Enter Zip code'
                   autoComplete='off'
                   value={user_zipcode}
                   style={inputStyle}
                   onChange={(e) => setUserZipCode(e.target.value)}
                 />
               </div>
             </div>


           </>
         )}
 
         {step === 2 && (
           <>
             <div className="row12345" style={rowStyle}>
               <div className="col-md-6">
                 <label className="form-label12345">Role</label>
                 <input
                   type="text"
                   className="form-control"
                   value={partner.title} 
                   readOnly
                   style={inputStyle}
                 />
               </div>
 
               <div className="col-md-6">
                 <label className="form-label12345">Domain</label>
                 <input
                   type="text"
                   className="form-control"
                   value={partner.domain} 
                   readOnly
                   style={inputStyle}
                 />
               </div>
             </div>

             <div className="row12345" style={rowStyle}>
               <div className="col-md-12">
                 <label className="form-label12345">LinkedIn Profile URL <span style={requiredStyle}>*</span></label>
                 <input
                   className="form-control"
                   placeholder='Enter Linkden Profile URL'
                   autoComplete='off'
                   value={user_linkden}
                   style={inputStyle}
                   onChange={(e) => setUserLinkden(e.target.value)}
                 />
               </div>
             </div>

             <div className="row12345" style={rowStyle}>
               <div className="col-md-12">
                 <label className="form-label12345">Website/Portfolio URL <span style={requiredStyle}>*</span></label>
                 <input
                   className="form-control"
                   placeholder='Enter Website/Portfolio URL'
                   autoComplete='off'
                   value={user_website}
                   style={inputStyle}
                   onChange={(e) => setUserWebsite(e.target.value)}
                 />
               </div>
             </div>



           </>
         )}

          {step === 3 && (
           <>
             <div className="row12345" style={rowStyle}>
               <div className="col-md-6">
                 <label className="form-label12345">Highest Degree Earned <span style={requiredStyle}>*</span></label>
                 <input
                   type="text"
                   className="form-control"
                   placeholder="(e.g., Bachelor, Master, PhD)"
                   autoComplete='off'
                   value={user_degree}
                   required
                   style={inputStyle}
                   onChange={(e) => setuser_Degree(e.target.value)}
                 />
               </div>
 
               <div className="col-md-6">
                 <label className="form-label12345">Field of Study <span style={requiredStyle}>*</span></label>
                 <input
                   type="text"
                   className="form-control"
                   placeholder='Enter Field of Study'
                   autoComplete='off'
                   value={user_feildstudy}
                   required
                   style={inputStyle}
                   onChange={(e) => setuser_FeildStudy(e.target.value)}
                 />
               </div>
             </div>

             <div className="row12345" style={rowStyle}>
               <div className="col-md-6">
                 <label className="form-label12345">University/College Name <span style={requiredStyle}>*</span></label>
                 <input
                   type="email"
                   className="form-control"
                   placeholder='Enter University/College Name'
                   autoComplete='off'
                   value={user_college}
                   required
                   style={inputStyle}
                   onChange={(e) => setuser_College(e.target.value)}
                 />
               </div>
 
               <div className="col-md-6">
                 <label className="form-label12345">Graduation Year <span style={requiredStyle}>*</span></label>
                 <input
                   type="tel"
                   className="form-control"
                   placeholder='Enter Mobile Number'
                   autoComplete='off'
                   value={user_year}
                   required
                   style={inputStyle}
                   onChange={(e) => setuser_Year(e.target.value)}
                 />
               </div>
             </div>

             <div className="row12345" style={rowStyle}>
              <div className="col-md-6">
                 <label className="form-label12345">Total Years of Experience <span style={requiredStyle}>*</span></label>
                 <input
                   className="form-control"
                   placeholder='Enter City'
                   autoComplete='off'
                   value={user_exprience}
                   style={inputStyle}
                   onChange={(e) => setUserExprience(e.target.value)}
                 />
               </div>
              </div>

             <div className="row12345" style={rowStyle}>
               
               <div className="col-md-6">
                 <label className="form-label12345">Previous Job Title <span style={requiredStyle}>*</span></label>

                 <input
                   className="form-control"
                   placeholder='Enter City'
                   autoComplete='off'
                   value={user_previousjob}
                   style={inputStyle}
                   onChange={(e) => setUserPreviousJob(e.target.value)}
                 />

               </div>
               <div className="col-md-6">
                 <label className="form-label12345">Previous Company Name <span style={requiredStyle}>*</span></label>
                 <input
                   className="form-control"
                   placeholder='Enter City'
                   autoComplete='off'
                   value={user_companyname}
                   style={inputStyle}
                   onChange={(e) => setUserCompanyName(e.target.value)}
                 />

               </div>
               
             </div>

             <div className="row12345" style={rowStyle}>
             <div className="col-md-12">
                 <label className="form-label12345">Key Responsibilities <span style={requiredStyle}>*</span></label>

                 <textarea
                   className="form-control"
                   placeholder='Enter Key Responsibilities and Achievements'
                   autoComplete='off'
                   value={user_responsibilities}
                   style={inputStyle}
                   onChange={(e) => setUserResponsibilities(e.target.value)}
                 />

               </div>

            </div>
             


           </>
         )}
 
         {step === 4 && (
           <>
            
 
             <div className="row12345" style={rowStyle}>
               <div className="col-md-6">
                 <label className="form-label12345">Resume <span style={requiredStyle}>*</span></label>
                 <input
                   type="file"
                   className="form-control"
                   accept=".pdf"
                   required
                   style={inputStyle}
                   onChange={handleFileChange}
                 />
                 {errors.file_resume && <p style={errorStyle}>{errors.file_resume}</p>}
               </div>
             </div>
           </>
         )}


 
         <div className="button-group" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
           {step > 1 && (
             <button type="button" style={{backgroundColor:'#007bff', borderRadius:'5px', boxShadow:'none'}} className="btn btn-primary" disabled={step === 1} onClick={prevStep}>
               Previous
             </button>
           )}
           {step < 4 && (
             <button type="button" style={{backgroundColor:'#007bff', borderRadius:'5px', boxShadow:'none'}} className="btn btn-primary" disabled={step === steps.length} onClick={nextStep}>
               Next
             </button>
           )}
           {step === 4 && (
             <button
               type="button"
               className="btn btn-primary"
               onClick={applyForJob}
               disabled={isSubmitting}
               style={{backgroundColor:'#007bff', borderRadius:'5px', boxShadow:'none'}}
             >
               {isSubmitting ? 'Submitting...' : 'Submit'}
             </button>
           )}
         </div>
       </form>
         
        ))}

         {Popupuser && (
           <Popup title={"Application Submitted Sucessfully!"} show={Popupuser} setShowPopUpUser={setPopupuser} />
         )}

      </div>
      <Footer data={footer} />
    </>
  );
}

export default CareerReg;
