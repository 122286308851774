import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './customcomponent.css';

import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs';
import CarouselCard from './carouselCard';
import cloudServices from '../../images/carouselImages/cServices.svg';
import cyberSecurity from '../../images/carouselImages/cb.png';
import db from '../../images/carouselImages/dbbass2.png';
import disaster from '../../images/carouselImages/DisiasterRecovery 1.png';
import ai from '../../images/carouselImages/ai.png';
import automation from '../../images/carouselImages/itorc.svg'
import hpc from '../../images/carouselImages/hpc1.png';
import sd from '../../images/carouselImages/file-removebg-preview.png';
import infra from '../../images/carouselImages/INFRA.png';
import server from '../../images/carouselImages/server.png'
const HeroCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToNextSlide = () => {
    setCurrentSlide(currentSlide === carouselData.length ? 0 : currentSlide + 1);
  };

  const goToPrevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? carouselData.length : currentSlide - 1);
  };
  const iwidth='700px'
  const TextColor ='#ffff';
  const dbwidth = '400px';
  const cswidth = '500px';
  const infraWidth = '900px'
  const carouselData = [
    { title: "Virtualization & Cloud ", subtitle: "Empower Your Business with Scalable Cloud Solutions",gradientColor:' linear-gradient(to right, #0052d4, #4364f7, #6fb1fc)' ,imageUrl:cloudServices,textColor:TextColor,buttonLink:'/splashScreen',Iwidth:cswidth,containerWidth:'700px'},
    { title: "Cyber Security", subtitle: "Protect Your Digital Assets from Cyber Threats",gradientColor:'linear-gradient( 288deg,  rgba(0,85,255,1) 1.5%, rgba(4,56,115,1) 91.6% )', imageUrl:cyberSecurity ,textColor:TextColor,buttonLink:'/cybersecurity'} ,
    { title: "Data & AI", subtitle: "Unlock Insights and Drive Innovation with Data and AI Solutions",gradientColor:'linear-gradient( 108.7deg,  rgba(221,22,224,1) 11%, rgba(111,22,190,1) 88.2% )',textColor:TextColor,imageUrl:ai ,buttonLink:'/dataai' },
    { title: "Database Services", subtitle: "Ensure Optimal Performance and Security for Your Databases",gradientColor:'linear-gradient( 83.2deg,  rgba(150,93,233,1) 10.8%, rgba(99,88,238,1) 94.3% )',textColor:TextColor,imageUrl:db,buttonLink:'/database-services',Iwidth:dbwidth },
    { title: "Disaster Recovery & Backup", subtitle: "Minimize Downtime and Secure Your Data Against Disasters",gradientColor:'linear-gradient(to right, #0f0c29, #302b63, #24243e)',textColor:TextColor,imageUrl:disaster,buttonLink:'/disaster&backup',marginleftvalue:'100px' },
    { title: "High Performance Computing",Iwidth:iwidth, subtitle: "Boost Your Computational Power for Complex Workloads",gradientColor:' linear-gradient(to right, #ff0084, #33001b)',textColor:TextColor,imageUrl:hpc,buttonLink:'/hpc' },
    { title: "Infrastructure Managed Services",mbt:'50px',marginleftvalue:'20px', subtitle: "Streamline Operations and Optimize Your IT Infrastructure",gradientColor:'linear-gradient(90deg, hsla(41, 100%, 70%, 1) 0%, hsla(7, 76%, 47%, 1) 100%)',textColor:TextColor,imageUrl:infra ,buttonLink:'/iass',Iwidth:infraWidth },
    { title: "IT Automation & Orchestration", subtitle: "Automate Routine Tasks and Improve Efficiency in IT Operations",gradientColor:'linear-gradient(to right, #780206, #061161)',imageUrl:automation,textColor:TextColor ,buttonLink:'/itautomation'},
    { title: "Software Development", subtitle: "Transform Your Ideas into Robust and Scalable Software Solutions",gradientColor:'linear-gradient( 126.3deg,  rgba(30,2,83,1) 32.2%, rgba(198,55,160,0.46) 109.2% )',textColor:TextColor,imageUrl:sd,buttonLink:'/softwareDevelopment',marginleftvalue:'100px' },
    { title: "Unix & Storage Consulting", subtitle: "Maximize the Performance and Reliability of Your Unix Systems and Storage Solutions",gradientColor:' linear-gradient( 65.9deg,  rgba(85,228,224,1) 5.5%, rgba(75,68,224,0.74) 54.2%, rgba(64,198,238,1) 55.2%, rgba(177,36,224,1) 98.4% )', textColor:TextColor,buttonLink:'/unix',imageUrl:server,Iwidth:cswidth,marginleftvalue:'100px',}
  ];
  
  return (
    // <Container fluid>
    <div className='Carousel__MainCont'>
   
      <Carousel
         showThumbs={false}
         showArrows={true}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}   
        stopOnHover={false} 
        showStatus={false}
        selectedItem={currentSlide}
        showIndicators={true}
        centerMode
        centerSlidePercentage={95}
        
      
      >
          
      {carouselData.map((item, index) => (
        <CarouselCard key={index} title={item.title} subtitle={item.subtitle} gradientColor={item.gradientColor} imageUrl={item.imageUrl} textColor={item.textColor} Iwidth={item.Iwidth} buttonLink={item.buttonLink} 
         containerWidth={item.containerWidth} marginleftvalue={item.marginleftvalue} mbt={item.mbt}
        />
      ))}
    
        

      </Carousel>
    
      
      
      <div  className='carouselButtonConatiner'>
        <div className="carbtnCont">
        <button className='carouselCircleButton' onClick={goToPrevSlide}>  < BsFillCaretLeftFill /></button>
        <button className='carouselCircleButton' onClick={goToNextSlide}>  <BsFillCaretRightFill /></button>
   
        </div>
         </div>
    </div>
    // </Container>
  );
};

export default HeroCarousel;
