import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import Title from "./Title";
import { Container, Row, Col } from "react-bootstrap";
import { useTrail, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

function FourBlocks({ data, classText, styleTwo, styleThree, blockHeight }) {
    const [blockHeight1, setBlockHeight] = useState(blockHeight); // Default block height
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger once when the component comes into view
        threshold: 0.1, // Trigger when 10% of the component is in view
    });

    // Check if data is available
    const itemCount = data && data.items ? data.items.length : 0; // Use a count or default to 0

    // Move useTrail here to avoid conditional calls
    const trail = useTrail(itemCount, {
        opacity: inView ? 1 : 0, // Fade in when in view
        x: inView ? 0 : -50, // Slide from left when in view
        config: { tension: 200, friction: 20 }, // Animation config
        delay: index => inView ? index * 150 : 0, // Delay each item in the trail based on its index
    });

    useEffect(() => {
        const updateBlockHeight = () => {
            if (window.innerWidth <= 767) {
                setBlockHeight(blockHeight + '100px'); // Adjust block height for mobile devices
            } else {
                setBlockHeight(blockHeight); // Default block height for larger screens
            }
        };

        updateBlockHeight();
        window.addEventListener('resize', updateBlockHeight);

        return () => {
            window.removeEventListener('resize', updateBlockHeight);
        };
    }, [blockHeight]);

    if (!data || !data.items) {
        return <Loading />;
    }

    return (
        <section className={`${classText} BSservice`} ref={ref}>
            <Container>
                <Row>
                    <Col>
                        <Title
                            subtitle={data.desc}
                            title={data.title}
                            classText={`${
                                styleTwo
                                    ? "title-shape title-shape-sky-blue text-center"
                                    : styleThree
                                        ? "title-shape title-shape-c2 title-shape-style-three text-center"
                                        : "title-shape text-center"
                            }`}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    {trail.map((props, index) => (
                        <Col
                            key={index}
                            lg={3} // 4 columns on large screens (4x3=12, 12 is a full row in Bootstrap)
                            md={6} // 2 columns on medium screens
                            sm={6} // 2 columns on small screens
                            xs={12} // 1 column on extra small screens (mobile)
                            className="mb-4"
                        >
                            <animated.div
                                className={`single-services ${
                                    styleTwo
                                        ? "style--two"
                                        : styleThree
                                            ? `style--three`
                                            : ""
                                }`}
                                style={{
                                    ...props, // Apply the spring animation here
                                    height: blockHeight1,
                                   
                                }}
                            >
                                <div className="service-contents">
                                    <h3 style={{ marginBottom: "15px" }}>{data.items[index].title}</h3>
                                    <p>{data.items[index].desc}</p>
                                </div>
                            </animated.div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

FourBlocks.defaultProps = {
    classText: "pt-120 pb-90",
};

export default FourBlocks;
